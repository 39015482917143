<template>
    <div class="permissionTemplate">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li><router-link to="/permissions">Permissions</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">{{ template.name === "" ? 'New Permission Template' : template.name }}</a></li>
            </ul>
        </nav>

        <fieldset :disabled="isLoading">
            <div class="field is-grouped">
                <div class="control is-expanded">
                    <h1 class="title">Permission Template</h1>
                </div>
                <p class="control">
                    <button class="button is-primary" @click="savePermissionTemplate">
                        Save
                    </button>
                </p>
            </div>
       
            <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>

            <div class="field">
                <label class="label">Name</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Permission template name..." v-model="template.name">
                </div>
            </div>

            <ProductPermissions :productAccess="templateArray" @accessUpdated="setTemplate" 
                        message="To avoid errors, please make sure at least one permission is selected below." />    
        </fieldset>
    </div>
</template>

<script>
    import { secureGet, securePost, securePut } from '../secureFetch.js';

    import Loading from '../components/Loading';
    import ProductPermissions from '../components/ProductPermissions';

    export default {
        name: 'PermissionTemplate',
        components: {
            Loading,
            ProductPermissions
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            templateArray: function() {
                return JSON.parse(this.template.templateJson);
            }
        },
        data() {
            return {
                isLoading: false,
                templateId: null,
                template: {
                    name: "",
                    templateJson: '[]'
                },
                errorMessage: null,
                defaultErrorMessage: "Something didn't work."
            }
        },
        mounted() {
            this.templateId = this.$route.params.templateId;
            this.getPermissionTemplate();
        },
        methods: {
            getPermissionTemplate() {
                if (this.templateId) {
                    this.isLoading = true;

                    secureGet(this.hostUrl + "v1/permissiontemplate/" + this.templateId)
                        .then(data => {
                            this.isLoading = false;

                            if (data && data.success) {
                                this.template = data.permissionTemplate;
                            }
                        });
                }
            },
            savePermissionTemplate() {
                this.isLoading = true;

                if (this.templateId) {
                    securePut(this.hostUrl + "v1/permissiontemplate/" + this.templateId, this.template)
                        .then((data) => {
                            this.isLoading = false;

                            if (data.success) {
                                this.$router.push('/permissions');
                            } else {
                                this.errorMessage = data.message;

                                if (!this.errorMessage) {
                                    this.errorMessage = this.defaultErrorMessage;
                                }
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
                else {
                    securePost(this.hostUrl + "v1/permissiontemplate/create-permission-template", this.template)
                        .then((data) => {
                            this.isLoading = false;

                            if (data.success) {
                                this.$router.push('/permissions');
                            } else {
                                this.errorMessage = data.message;

                                if (!this.errorMessage) {
                                    this.errorMessage = this.defaultErrorMessage;
                                }
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
            },
            setTemplate(template) {
                this.template.templateJson = JSON.stringify(template);
            },
        }
    };
</script>

<style scoped>
    td:last-child {
        width: 1em;
        padding: 0;
        white-space: nowrap;
    }
</style>