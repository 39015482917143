<template>
    <div class="mt-5">
        <div class="control has-top-border">
            <div class="is-flex">
                <h1 class="title">Product Permissions</h1>
                <font-awesome-icon class="collapsable-icon ml-3 mt-1" :icon="determineIcon()" @click="isCollapsed = !isCollapsed" />
            </div>
        </div>
        <div v-if="!isCollapsed">
            <article v-if="message" class="message">
                <div class="message-body">
                    {{ message }}
                </div>
            </article>
            <Loading v-if="isLoading" />
            <aside v-else class="menu">
                <div class="field column is-narrow">
                    <label class="label">Permission Template</label>
                    <div :class="templateDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button template-button is-justify-content-flex-start has-text-left" aria-haspopup="true" aria-controls="dropdown-menu" @click="templateDropdownActive = !templateDropdownActive" @blur="delayCloseTemplateDropdown">
                                <span class="is-flex-grow-1">{{ getSelectedTemplateName() }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="template-dropdown-menu" role="menu">
                            <div v-for="(template) in templates" class="dropdown-content">
                                <a role="button" :class="'dropdown-item'" @click="selectTemplate(template)">{{ template.name }}</a>
                            </div>
                        </div>
                    </div>
                    <button :disabled="selectedTemplate == null" class="button is-primary ml-4" @click="applyTemplate">
                        Apply
                    </button>
                </div>
                <ul v-for="(product, index) in products" :key="'product' + index" class="menu-list">
                    <li class="has-top-border">
                        <div class="columns">
                            <div class="column">
                                {{ product.name }}
                            </div>
                            <div class="column">
                                <label class="switch-label">
                                    <input type="checkbox" class="switch" v-model="product.view" @change="buildPermissions"> View
                                </label>
                            </div>
                            <div class="column">
                                <label class="switch-label">
                                    <input type="checkbox" class="switch" v-model="product.change" @change="buildPermissions"> Change
                                </label>
                            </div>
                            <div class="column">
                                <label class="switch-label">
                                    <input type="checkbox" class="switch" v-model="product.execute" @change="buildPermissions"> Execute
                                </label>
                            </div>
                            <div class="column">
                                <label class="checkbox">
                                    <input type="checkbox" @change="enableAll($event, product.id)" />
                                    Enable All
                                </label>
                            </div>
                        </div>
                    </li>
                    <li>
                        <ul>
                            <li v-for="(feature, index) in product.features" :key="product.id + '-feature' + index" :class="index % 2 === 0 ? ' feature-list has-bottom-border' : 'has-bottom-border has-background-alt'">
                                <div class="columns ml-1">
                                    <div class="column">
                                        {{ feature.name }}
                                    </div>
                                    <div class="column">
                                        <label class="switch-label">
                                            <input type="checkbox" class="switch" v-model="feature.view" @change="buildPermissions"> View
                                        </label>
                                    </div>
                                    <div class="column">
                                        <label class="switch-label">
                                            <input type="checkbox" class="switch" v-model="feature.change" @change="buildPermissions"> Change
                                        </label>
                                    </div>
                                    <div class="column">
                                        <label class="switch-label">
                                            <input type="checkbox" class="switch" v-model="feature.execute" @change="buildPermissions"> Execute
                                        </label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </aside>
        </div>
    </div>
</template>

<script>
    import { secureGet } from '../secureFetch.js';

    import Loading from "./Loading.vue"

    export default {
        name: "ProductPermissions",
        components: {
            Loading
        },
        props: {
            productAccess: Array,
            message: String,
            expandView: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            productAccess: function () {
                this.updateProductsWithPermissions();
            },
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            }
        },
        data() {
            return {
                isLoading: true,
                products: [],
                localProductAccess: [],
                templates: [],
                selectedTemplate: null,
                templateDropdownActive: false,
                isCollapsed: true,
            }
        },
        mounted() {
            this.getProductsAndFeatures();
            this.getPermissionTemplates();
            
            if (this.expandView) {
              this.isCollapsed = false;
            }
        },
        methods: {
            getProductsAndFeatures() {
                secureGet(this.hostUrl + "v1/product/all-products")
                    .then(data => {
                        if (data && data.success) {
                            this.products = data.products;

                            this.updateProductsWithPermissions();
                        }
                    });
            },
            updateProductsWithPermissions() {
                if (this.productAccess && this.productAccess.length > 0) {
                    for (let i = 0; i < this.productAccess.length; ++i) {
                        let productAccess = this.productAccess[i];

                        let product = this.products.find(x => x.id === productAccess.productId);

                        if (product) {
                            product.view = productAccess.access & 1;
                            product.change = productAccess.access & 2;
                            product.execute = productAccess.access & 4;

                            if (productAccess.featureAccess) {
                                for (let j = 0; j < productAccess.featureAccess.length; ++j) {
                                    let featureAccess = productAccess.featureAccess[j];
                                    let feature = product.features.find(x => x.id === featureAccess.featureId);

                                    if (feature) {
                                        feature.view = featureAccess.access & 1;
                                        feature.change = featureAccess.access & 2;
                                        feature.execute = featureAccess.access & 4;
                                    }
                                }
                            }
                        }
                    }

                    this.$forceUpdate();
                }

                this.isLoading = false;
            },
            buildPermissions() {
                if (this.products) {
                    this.localProductAccess = [];

                    for (let i = 0; i < this.products.length; ++i) {
                        let product = this.products[i];
                        let productAccess = {
                            productId: product.id,
                            featureAccess: []
                        };
                        let shouldAdd = false;

                        if (product.view || product.change || product.execute) {
                            productAccess.access = (product.view ? 1 : 0) | (product.change ? 2 : 0) | (product.execute ? 4 : 0);
                            shouldAdd = true;
                        }

                        if (product.features) {
                            for (let j = 0; j < product.features.length; ++j) {
                                let feature = product.features[j];

                                if (feature.view || feature.change || feature.execute) {
                                    productAccess.featureAccess.push({
                                        featureId: feature.id,
                                        access: (feature.view ? 1 : 0) | (feature.change ? 2 : 0) | (feature.execute ? 4 : 0)
                                    });
                                    shouldAdd = true;
                                }
                            }
                        }

                        if (shouldAdd) {
                            this.localProductAccess.push(productAccess);
                        }
                    }

                    this.$emit('accessUpdated', this.localProductAccess);
                }
            },
            enableAll(cbInput, productId) {
                let product = this.products.find((x) => x.id == productId);
                if (product != null) {
                    const index = this.products.indexOf(product)

                    if (cbInput.target.checked) {
                        product.view = 1;
                        product.change = 2;
                        product.execute = 4;

                        for (let i = 0; i < product.features.length; i++) {
                            product.features[i].view = 1;
                            product.features[i].change = 2;
                            product.features[i].execute = 4;
                        }
                    }
                    else {
                        product.view = 0;
                        product.change = 0;
                        product.execute = 0;

                        for (let i = 0; i < product.features.length; i++) {
                            product.features[i].view = 0;
                            product.features[i].change = 0;
                            product.features[i].execute = 0;
                        }
                    }
                    this.products.splice(index, 1, product);
                    this.buildPermissions();
                }
            },
            getPermissionTemplates() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/permissiontemplate/all-permission-templates")
                    .then(data => {
                        if (data && data.success) {
                            this.templates = data.permissionTemplates;
                            this.templates.sort((a, b) => (a.name > b.name) ? 1 : -1);
                            this.isLoading = false;
                        }
                    });
            },
            getSelectedTemplateName() {
                if (this.selectedTemplate != null) {
                    return this.selectedTemplate.name;
                }
                return 'Select Permission Template to Apply';
            },
            selectTemplate(template) {
                this.selectedTemplate = template;
                this.templateDropdownActive = false;
            },
            resetPermissions() {
                if (this.products && this.products.length > 0) {
                    for (let i = 0; i < this.products.length; ++i) {
                        const product = this.products[i];
                        product.view = false;
                        product.change = false;
                        product.execute = false;

                        if (product.features && product.features.length > 0) {
                            for (let j = 0; j < product.features.length; j++) {
                                let feature = product.features[j];
                                feature.view = false;
                                feature.change = false;
                                feature.execute = false;
                            }
                        }
                    }
                }
                this.isLoading = false;
            },
            applyTemplate() {
                if (this.selectedTemplate == null) {
                    return;
                }

                this.resetPermissions();

                const parsedTemplate = JSON.parse(this.selectedTemplate.templateJson);
                this.localProductAccess = [];

                for (let i = 0; i < parsedTemplate.length; i++) {
                    let productAccess = parsedTemplate[i];
                    let product = this.products.find(x => x.id === productAccess.productId);

                    if (product) {
                        product.view = productAccess.access & 1;
                        product.change = productAccess.access & 2;
                        product.execute = productAccess.access & 4;

                        if (productAccess.featureAccess) {
                            for (let j = 0; j < productAccess.featureAccess.length; ++j) {
                                let featureAccess = productAccess.featureAccess[j];
                                let feature = product.features.find(x => x.id === featureAccess.featureId);

                                if (feature) {
                                    feature.view = featureAccess.access & 1;
                                    feature.change = featureAccess.access & 2;
                                    feature.execute = featureAccess.access & 4;
                                }
                            }
                        }
                    }

                    this.localProductAccess.push(productAccess);
                }

                this.products = [...this.products];
                this.$emit('accessUpdated', this.localProductAccess);
                this.isLoading = false;
            },
            determineIcon() {
                if (this.isCollapsed) {
                    return ['fa', 'angle-up'];
                }
                return ['fa', 'angle-down'];
            },
            delayCloseTemplateDropdown() {
                setTimeout(() => this.templateDropdownActive = false, 500);
            },
        }
    }
</script>

<style scoped>
    .has-bottom-border {
        border-bottom: 1px solid #808080;
        padding-bottom: 1em;
    }

    .feature-list:first-child {
        border-top: 1px solid #808080;
        padding-top: 1em;
    }

    .has-background-alt {
        background-color: #141514;
        margin-top: -1.425em;
    }

    .collapsable-icon {
        height: 32px;
        width: 32px;
    }

    .template-button {
        min-width: 25em;
    }
</style>